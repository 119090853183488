#icon_wrapper {
    position: fixed;
    top: 25%;
    left: 0px;
    z-index: 99999;
}
.awesome-social {
    margin-top: 3px;
    color: white !important;
    text-align: center !important;
    line-height: 45px !important;
    width: 45px !important;
    height: 45px !important;
    font-size: 1.5em !important;
    text-shadow: 2px 2px 4px #000000;
    -moz-transition: width s, height s, -webkit-transform s;
    -webkit-transition: width s, height s, -webkit-transform s;
    transition: width s, height s, transform s;
}
.fb-bg{
  background: #3a549f;
}
.tw-bg{
  background: #50abf1;
}
.linkedin-bg{
  background: #0085b2;
}
.ins-bg{
  background: #437ea8;
}
