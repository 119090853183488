.blockCard {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 250px;
}

.blockCard.hideCard .textCard{
  top: calc(100% - 1.15rem - 30px);
}

.textCard {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 15px 20px;
  color: #fff;
  overflow: hidden;
  transition: all 0.6s;
  -o-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}
.textCard .caption{
  color: #fff;
}
.blockCard img {
  width: 100%;
}
.thumb-overlay {
  background-color: $brand-primary;
}
.thumb-overlay.visible {
  background: rgba($brand-primary,.6);
}

/* Youtube  */

.oembed-provider-youtube, .oembed-provider-youtube iframe{
  width:100%;
  height: 500px;
}
