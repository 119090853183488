.form-control {
	color: $gray-dark;
}
input::-webkit-input-placeholder, input:-ms-input-placeholder, input::-ms-input-placeholder{
	color: $gray-dark;
}
.formEmailinput:focus, .formTextinput:focus, .formTextarea:focus, .formTelinput { border-color: transparent; }

.formTextarea {
	overflow: auto;
}
.form{
    max-width: 100%;
    margin: 0 auto;
		padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.formHolder{
    width: 100%;
    text-align: center;
}
.form input, .form textarea {
    width: 100%;
}
.w-10{
  width:10%;
}
.blue-wrap{
  background-color: $blue;
  margin: 0 -3.5rem;
}
.border-wrap{
  margin: 0 -3.5rem;
}
.left-border{
  height: 0;
  width: 0;
  border: .75rem;
  border-style: solid;
  border-color: $blue $blue transparent transparent;
}
.right-border{
  height: 0;
  width: 0;
  border: .75rem;
  border-style: solid;
  border-color: $blue transparent transparent $blue;
}
hr{
  border-top: 3px solid $white;
}
.sub-heading .text-left{
  text-align:center !important;
}
.sub-heading .text-left p{
  padding-bottom: 1rem;
}
.d-date .formField-select{
  flex: 1;
}
.d-date .formField-select:first-child{
   flex: 1.5;
}
.d-date .formField-select:nth-child(2){
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
